import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

const miod = {
  color: '#ca9502' 
}

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/generalSlides/szkola-animatora-3-2019-2020.jpg'} alt={'plakat szkoła animatora 3 2019'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
            <div>
              <h2 className="miod"><strong style={miod}>DIECEZJALNA SZKOŁA ANIMATORA</strong> </h2>
              <p><em>SPOTKANIE TRZECIE
                 - 23.11.2019 r. w Domu Miłosierdzia w Otmuchowie</em> </p>

              <p className="miod"><strong style={miod}>Notatka ze spotkania:</strong></p>

              <p>W święto Nawrócenia św. Pawła Apostoła Pan Bóg pozwolił nam przeżyć kolejne, już trzecie, radosne
                spotkanie w Szkole Animatora.
              </p>
              <p>
                Po przyjęciu drogą losowania posług, modlitwie do Ducha Świętego z zainteresowaniem wysłuchaliśmy
                ciekawego wykładu księdza Artura Kasprzyckiego pt: " Ja wobec kerygmatu".
              </p>
              <p>
                W swoim wystąpieniu ksiądź A. Kasprzycki podzielił się z nami swoją refleksją po analizie ankiet, które
                wypełnialiśmy na spotkaniu pierwszym, zapoznał nas z istotą kerygmatu, przedstawił zależności między
                4 zasadami duchowego życia, zwracając uwagę na sposób głoszenia i możliwe problemy. 
              </p>
              <p><strong style={miod}>"Głoszenie kerygmatu nie jest celem, ale środkiem, który ma doprowadzić słuchacza do wyboru!"</strong>, powiedział ksiądz
                Artur Kasprzycki.
              </p>
              <p>
                Podczas uroczystej mszy świętej homilię wygłosił ksiądź Eugeniusz Ploch. " W nawróceniu św. Pawła
                Bóg pokazuje nam, na czym polega kerygmat" powiedział ks.E. Ploch zachęcając, abyśmy
                w każdej homilii szukali kerygmatu.
              </p>
              <p>
                Po krótkiej przerwie na kawę wzięliśmy udział w warsztatach, podejmując próbę przygotowania
                świadectwa uwzgędniającego poznane wcześniej prawa głoszenia kerygmatu z uwzględnieniem zasady
                RKS.
              </p>
              <p>
                Spotkanie zakończyliśmy Koronką do Bożego Miłosierdzia i uwielbienem PANA.
              </p>

              <img src={'/animator-3/1.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-3/2.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-3/3.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-3/4.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-3/5.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-3/6.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-3/7.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-3/8.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
